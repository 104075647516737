<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix" style="margin-bottom: 20px">
      <h3 class="inBlock">九宫格奖品列表</h3>
    </div>
    <div slot="header" class="clearfix">
      <!-- <router-link to="addnews"> -->
        <el-button type="primary" @click="handleClick" plain size="small"> +添加奖品 </el-button>
      <!-- </router-link> -->
      <div class="tiaojian">
        <div class="left">
          <strong>奖品名称: </strong
          ><el-input
            v-model="formdata.name"
            placeholder="请输入奖品名称"
          ></el-input>
        </div>
        <span class="shaixuan shou" @click="filterList">筛选</span>
      </div>
    </div>

    <el-table :default-sort = "{prop: 'sort', order: 'descending'}"
     :data="tableData"
    height="500"
    border
    style="width: 100%" 
    @selection-change="handleSelectionChange"
    >
      <!-- <el-table-column
      type="selection"
      width="55">
    </el-table-column> -->
      <el-table-column label="奖品名称" prop="name"> </el-table-column>
      <el-table-column label="中奖概率基数" prop="odds"></el-table-column>
        <el-table-column label="中奖概率基数">
        <template slot-scope="scope">
          {{ scope.row.chance }}%
        </template></el-table-column>
      <el-table-column label="最大中奖数" prop="max_num"> </el-table-column>
      <el-table-column label="已中奖数" prop="now_num"> </el-table-column>
      <el-table-column label="添加时间">
        <template slot-scope="scope">
          {{ scope.row.createtime}}
        </template>
      </el-table-column>
      <el-table-column label="是否展示">
                  <template slot-scope="scope">
                    <el-switch
                    v-model="scope.row.status" 
                    active-color="#13ce66"
                    inactive-color="#999"
                    :active-value="1"
                    :inactive-value="2"
                    @change="luckdrawsChanges(scope.row.id)"
                    />
                </template>
            </el-table-column>
      <el-table-column label="添加人" prop="user_login"> </el-table-column>
      <el-table-column label="操作" width="230">
        <template slot-scope="scope">
          <el-button
            @click="editData(scope.row.id)"
            type="text"
            size="small"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 表单提交 -->
    <el-dialog :title="title" :visible.sync="dialogVisible" width="80%">
        <el-form
            :model="form"
            :rules="rules"
            ref="liveFrom"
            label-width="100px"
          >
            <el-form-item label="选择商品" style="margin-top: 15px">
              <el-button plain size="small" @click="chooseProduct"
                >点击选择</el-button
              >
              <div class="selectedProList">
                <div
                  class="selectedProItem"
                  v-for="(item2, index2) in selectProList"
                  :key="index2"
                >
                  <img :src="imgurl + item2.cover_pic" alt="" srcset="" />
                  <div class="probottom">
                    <p>{{ item2.name }}</p>
                    <span>{{ item2.price }}</span>
                  </div>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="商品图片">
              <div class="imgbox">
                <div class="imgItem" v-if="form.pic">
                  <span class="shou" @click="delImg">×</span>
                  <img class="avatar" :src="imgurl + form.pic" alt="" />
                </div>
                <up-img v-else @addimg="upDataimg" :type="'goods'"></up-img>
              </div>

            </el-form-item>
            <el-form-item label="奖品名称">
                <el-input style="width: 45%" v-model="form.name"></el-input>　　如设置"谢谢惠顾"类似奖项,则不选择商品直接在上方上传图片,此处输入奖项名称即可
            </el-form-item>
             <el-form-item label="中奖概率基数">
                <el-input style="width: 45%" v-model="form.odds"></el-input>　　整数(数量越大中奖率越高,如只显示不中奖基数设置为0)
            </el-form-item>
             <el-form-item label="总发放数量">
                <el-input style="width: 45%" v-model="form.max_num"></el-input>　　个(0个表示不限制中奖数量)
            </el-form-item>
            
             <el-form-item label="排序">
                <el-input style="width: 45%" v-model="form.sort"></el-input>
            </el-form-item>
            <el-form-item label="是否显示">
              <el-radio v-model="form.status" :label="1">是</el-radio>
              <el-radio v-model="form.status" :label="2">否</el-radio>
            </el-form-item>
          </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="warning" @click="dialogVisible = false">关闭</el-button>
        <el-button type="primary" @click="submitProSele">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 选择商品列表 -->
    <el-dialog title="选择商品" :visible.sync="chooseprodialog" width="30%">
      <div class="productList">
         <div class="tiaojian">
            <div class="left">
              <strong>商品名称: </strong
              ><el-input
                v-model="lotteryForm.name"
                placeholder="请输入商品名称"
              ></el-input>
            </div>
          <span class="shaixuan shou" @click="filterListModel">筛选</span>
        </div>
        <el-table
          row-key="id"
          ref="multipleTable"
          :data="liveProductList"
          tooltip-effect="dark"
          style="width: 100%"
        >
        <el-table-column label="操作">
            <template slot-scope="scope">
            <el-button
                @click="selectShop(scope.row)"
                type="text"
                size="small"
                >选择</el-button
            >
            </template>
        </el-table-column>
          <el-table-column label="商品ID" width="120">
            <template slot-scope="scope">{{ scope.row.id }}</template>
          </el-table-column>
          <el-table-column prop="name" label="商品名称" width="200">
            <template slot-scope="scope">{{ scope.row.name }}</template>
          </el-table-column>
          <el-table-column
            prop="address"
            label="商品价格"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{ scope.row.price }}</template>
          </el-table-column>
          
        </el-table>
        <!-- <el-button @click="toggleSelection(data)">切换第二、第三行的选中状态</el-button> -->
         <el-pagination
          background
          style="margin-top: 20px"
          @current-change="crtChg"
          :page-size="lotteryForm.limit"
          :current-page="lotteryForm.page"
          layout="total, prev, pager, next"
          :total="modelTotal"
        >
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="chooseprodialog = false">取 消</el-button> -->
        <el-button type="primary" @click="chooseProSele">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>
<script>
import {
  getLotteryList,
  lotteryChoiceGoods,
  postLotterySave,
  getLotteryChange,
  getLotteryDetails,
} from "@/api/api.js";
import UpImg from "@/components/upImg.vue";
import { getSystemsettings } from "../../api/getSystemsetting.js";
export default {
    components:{
        UpImg
    },
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      title: "奖品添加",
      total: 0,
      modelTotal: 0,
      imgurl: '',
      chooseprodialog: false,
      liveProductList: [],
      selectProList: [], //选中商品
      form: {
        id: '', //0为添加 大于0为修改
        goods_id: 0, //商品id
        price: '', //商品价格用于详情修改
        pic: '', //图片
        name: '', //商品名称
        odds: '', //中奖概率
        sort: '', //排序  倒叙
        status: 1, //状态 1显示  2隐藏
        max_num: '', //最大中奖数  0  不限制
      },
      rules: {
        name: [
          { required: true, message: "请选择商品名称", trigger: "blur" },
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        odds: [
          { required: true, message: "请填写中奖概率", trigger: "blur" },
        ],
        sort: [
          { required: true, message: "请选择顺序", trigger: "blur" },
        ],
        pic: [{ required: true, message: "请上传相关图片", trigger: "blur" }],
      },
      formdata: {
        page: 1,
        limit: 10,
        name: "",
      },
      lotteryForm: {
        limit: 7,
        page: 1,
        id: '',
        name: ''
      },
    };
  },
  methods: {
    async getlist() {
      const { data } = await getLotteryList(this.formdata);
      console.log(data);
      if (data.code != 200) return this.$message.error(data.data);
      this.tableData = data.data.list.data;
      this.total = data.data.list.total;
    },
    selectShop(row){
        this.selectProList = []
        this.selectProList.push(row)
        this.form.goods_id = row.id
        this.form.name = row.name
        this.form.pic = row.cover_pic
        this.form.price = row.price
        console.log(row)
    },
    // 商品列表确定
    chooseProSele() {
      this.chooseprodialog = false;
    },
    // 组件切换分页
    crtChg(e) {
      this.lotteryForm.page = e;
      this.getProductlist();
    },
    // 奖品提交
    async submitProSele(){
        const { data } = await postLotterySave(this.form);
        console.log(data);
        if (data.code != 200) return this.$message.error(data.data);
        this.dialogVisible = false
         this.getlist();
    },
    // 图片上传
    upDataimg(e) {
      this.form.pic = e
    },
    // 图片删除
    delImg() {
     this.form.pic = ''
    },
    // 表单多选
    handleSelectionChange(val){
        console.log('val===',val)
    },
    // 是否展示
    async luckdrawsChanges(id){
      const { data } = await getLotteryChange({ id: id });
      this.getlist()
      if (data.code != 200) return this.$message.error(data.data);
      this.$message({
        type: "success",
        message: "操作成功!",
      });
      console.log(id)
    },
    // 选择商品按钮
    chooseProduct() {
      this.chooseprodialog = true;
      this.getProductlist();
    },
    // 获取奖品商品列表
    async getProductlist() {
      this.lotteryForm.name = ''
      const { data } = await lotteryChoiceGoods(this.lotteryForm);
      if (data.code != 200) return this.$message.error(data.data);
      console.log(data)
      this.liveProductList = data.data.data;
      this.modelTotal = data.data.total;
    },
    // 获取商品列表搜索
    async filterListModel(){
      this.lotteryForm.page = 1
      const { data } = await lotteryChoiceGoods(this.lotteryForm);
      if (data.code != 200) return this.$message.error(data.data);
      console.log(data)
      this.liveProductList = data.data.data;
      this.modelTotal = data.data.total;
      this.lotteryForm.name = ''
    },
    // 添加奖品
    handleClick() {
        this.title = '奖品添加'
        this.selectProList = []
        this.form.id = ''
        this.form.goods_id = ''
        this.form.pic = ''
        this.form.name = ''
        this.form.odds = ''
        this.form.sort = ''
        this.form.max_num = ''
        this.form.status = 1
        this.dialogVisible = true;
    },
    // 编辑
    async editData(id){
        this.title = '编辑中奖信息'
        this.dialogVisible = true;
        const { data } = await getLotteryDetails({ id: id });
        if (data.code != 200) return this.$message.error(data.data);
        this.form.id = data.data.id
        this.form.goods_id = data.data.goods_id
        this.form.pic = data.data.pic
        this.form.name = data.data.name
        this.form.odds = data.data.odds
        this.form.sort = data.data.sort
        this.form.max_num = data.data.max_num
        this.form.status = data.data.status
        if(this.form.goods_id != 0){
            this.selectProList = []
            let row = {
                goods_id: data.data.goods_id,
                name: data.data.name,
                cover_pic: data.data.pic,
                price: data.data.price
            }
            this.selectProList.push(row)
            console.log( this.selectProList)
        }
        console.log(data)
        // this.getlist()
        console.log(id)
    },
    // 列表分页
    handleCurrentChange(val) {
      this.formdata.page = val;
      this.getlist();
    },
    // 搜索
    filterList() {
      console.log(123);
      this.formdata.page = 1;
      this.getlist();
    },
  },
  mounted() {
    getSystemsettings.then((res) => {
      this.imgurl = res;
    });
    this.getlist();
  },
};
</script>
<style lang='less'>
.con img {
  width: 100%;
}
.color0 {
  color: red;
}
.color1 {
  color: royalblue;
}
.color2 {
  color: #da0618;
}
.tiaojian {
  margin: 20px 0;
  padding: 10px;
  display: flex;
  align-items: center;
  background-color: #edeff0;
  position: relative;
  .left {
    strong {
      font-weight: normal;
      font-size: 14px;
      color: #606266;
      padding-top: 10px;
      margin-right: 2px;
    }
    .el-input {
      width: 300px;
      margin-right: 20px;
      /deep/.el-input__inner {
        height: 30px;
      }
    }
    .checkboxBox {
      padding-top: 10px;
      margin: 0 0 20px 0;
    }
  }
  .shaixuan {
    background-color: #18bdff;
    color: aliceblue;
    padding: 3px 10px;
    border-radius: 2px;
    position: absolute;
    bottom: 15px;
    left: 400px;
    &:hover {
      background-color: #1abe9c;
    }
  }
}
/deep/.el-dialog__wrapper .el-dialog{
  height: 100%;
  overflow-y: scroll;
}
.box {
  .liveitem {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .label {
      flex: 1;
      border: 1px solid #eee;
      text-align: center;
      padding-top: 40px;
      font-weight: 900;
      font-size: 16px;
      margin-right: 10px;
    }
    .content {
      flex: 8;
      border: 1px solid #eeeeee;
      padding: 20px;
      .el-form {
        .el-form-item {
          .el-dropdown {
            span {
              border: 1px solid #c0c4cc !important;
              color: #777;
            }
          }
          
          .el-dropdown {
            span {
              padding: 5px 10px;
              border: 1px solid #333;
            }
          }
          .el-input {
            width: 375px;
          }
        }
      }
     
    }
  }
}
.selectedProduct p {
  line-height: 30px;
}
.el-table /deep/td {
  padding: 10px 0;
}
.submit {
  padding-top: 20px;
  border-top: 1px solid #eee;
  margin-bottom: 50px;
  span {
    background-color: #19c2ff;
    padding: 5px 10px;
    margin-right: 10px;
    color: #fff;
    &:last-child {
      background-color: #1bc6a3;
    }
  }
}
.imgbox {
    display: flex;
    .imgItem {
        width: 178px;
        height: 178px;
        padding: 10px;
        margin-left: 10px;
        border-radius: 5px;
        position: relative;
        border: 1px solid #eee;
        span {
        position: absolute;
        background-color: #ffd1d1;
        top: 0;
        right: 0;
        height: 20px;
        text-align: center;
        line-height: 20px;
        width: 20px;
        z-index: 100;
        color: aliceblue;
        &:hover {
            background-color: #ff4544;
        }
        }
    }
    .avatar {
        width: 100%;
        height: 100%;
    }
}
 .selectedProList {
        display: flex;
        flex-wrap: wrap;
        .selectedProItem {
          width: 100px;
          height: 175px;
          border: 1px solid #efefef;
          margin-right: 10px;
          img {
            width: 98px;
            height: 98px;
          }
          .probottom {
            padding: 6px;
            p {
              line-height: 20px;
              font-size: 12px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 86px;
            }
            span {
              color: #ff4544;
            }
          }
        }
      }
.tiaojian {
  margin: 20px 0;
  padding: 10px;
  display: flex;
  align-items: center;
  background-color: #edeff0;
  position: relative;
  .left {
    strong {
      font-weight: normal;
      font-size: 14px;
      color: #606266;
      padding-top: 10px;
      margin-right: 2px;
    }
    .el-input {
      width: 300px;
      margin-right: 20px;
      /deep/.el-input__inner {
        height: 30px;
      }
    }
    .checkboxBox {
      padding-top: 10px;
      margin: 0 0 20px 0;
    }
  }
  .shaixuan {
    background-color: #18bdff;
    color: aliceblue;
    padding: 3px 10px;
    border-radius: 2px;
    position: absolute;
    bottom: 15px;
    left: 400px;
    &:hover {
      background-color: #1abe9c;
    }
  }
}
</style>

<style scoped lang='less'>
/deep/.el-dialog__body{
		padding-top: 0px !important;
		padding-bottom: 0px !important;
	}
</style>